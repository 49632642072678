// src/components/card/Card.js

import React from 'react';
import { Box } from '@chakra-ui/react';

const Card = ({ children, ...rest }) => {
  return (
    <Box
      borderRadius="20px" // Cantos arredondados
      boxShadow="md" // Sombra para destacar o card
      p="20px" // Padding interno
      bg="white" // Cor de fundo branca (pode ajustar com tema)
      {...rest} // Props adicionais
    >
      {children} {/* Conteúdo dentro do card */}
    </Box>
  );
};

export default Card;
