import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Imagem importada
import logoblack from "../../../assets/images/azulclarobuscai.png"; // Caminho correto da imagem
import { HSeparator } from "../../separator/Separator"; // Caminho correto do separador

export function SidebarBrand() {
  // Definição da cor do fundo do componente, se necessário
  const bgColor = useColorModeValue("white", "navy.800");

  return (
    <Flex
      align="center"
      direction="column"
      bg={bgColor}
      borderRadius="20px"
      p="24px"
    >
      <img
        src={logoblack}
        alt="Logo"
        style={{
          maxWidth: "175px", // Largura máxima para a imagem
          height: "auto", // Altura ajustada automaticamente para manter a proporção
          marginTop: "32px",
          marginBottom: "32px",
          objectFit: "contain", // Mantém a proporção da imagem dentro das dimensões especificadas
        }}
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
