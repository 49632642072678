// src/views/admin/default/index.jsx

import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";
import { Navigate } from 'react-router-dom';

import AnnouncementCarousel from "./components/AnnouncementCarousel";
import SelectAndInput from "./components/SelectAndInput";
import TotalSpent from './components/TotalSpent'; // Importação do TotalSpent
// Remova a importação duplicada do LastConsultation
// import LastConsultation from './components/LastConsultation'; // Importação do LastConsultation (REMOVIDA)

export default function UserReports() {
  // Estados e hooks
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [availableConsultations] = useState(10); // Valor inicial de exemplo
  const [totalConsultations] = useState(20); // Valor inicial de exemplo

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem('authToken');
      // Verifica se o token existe e define a autenticação
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  // Estado de carregamento ou verificação em progresso
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  // Handlers para SelectAndInput
  const handleSelectChange = (value) => setSelectedValue(value);
  const handleInputChange = (value) => setInputValue(value);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Carousel no topo da página */}
      <Box mb='20px'>
        <AnnouncementCarousel />
      </Box>

      {/* TotalSpent logo abaixo do Carousel */}
      <Box mb='20px'>
        <TotalSpent
          availableConsultations={availableConsultations}
          totalConsultations={totalConsultations}
        />
      </Box>

      {/* Componente SelectAndInput */}
      <Box mb='20px'>
        <SelectAndInput
          onSelectChange={handleSelectChange}
          onInputChange={handleInputChange}
          selectedValue={selectedValue}
          inputValue={inputValue}
        />
      </Box>

      {/* Remova o componente LastConsultation daqui para evitar duplicação */}
      {/* <Box mb='20px'>
        <LastConsultation />
      </Box> */}

    </Box>
  );
}
