import { useState, useEffect } from 'react';
import { Box, Text, Flex, IconButton, Button, useColorModeValue, keyframes } from '@chakra-ui/react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

const announcements = [
  "🚗 Novas consultas de veículos disponíveis!",
  "💳 Pontuação de crédito atualizada!",
  "🚨 Roubos e furtos próximos!",
  "⚰️ Verificação de óbito agora disponível.",
  "🔥 Explore nossos novos recursos investigativos!"
];

// Animação para o texto
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Animação de pulsação leve para o botão
const pulseEffect = keyframes`
  0%, 100% {
    transform: scale(1); // Tamanho normal
  }
  50% {
    transform: scale(1.03); // Leve aumento
  }
`;

const AnnouncementCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
    }, 2500); // Trocar a cada 2.5 segundos

    return () => clearInterval(intervalId);
  }, []);

  const nextAnnouncement = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
  };

  const prevAnnouncement = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + announcements.length) % announcements.length);
  };

  // Definindo uma cor principal neutra e minimalista
  const buttonBg = useColorModeValue("#F1F3F5", "#2C2C2E");
  const buttonHoverBg = useColorModeValue("#E5E7EB", "#3A3A3C");
  const buttonTextColor = useColorModeValue("#1C1C1E", "#FFFFFF");

  return (
    <Box
      p="24px"
      bg={useColorModeValue("gray.50", "gray.900")}
      borderRadius="16px"
      shadow="xl"
      width="100%"
      maxW="full"
      mx="auto"
      overflow="hidden"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      transition="all 0.3s ease"
      _hover={{ shadow: "2xl" }}
      mt={{ base: '-4', md: '0' }}
    >
      <Flex
        align="center"
        justify="space-between"
        width="100%"
        mb="4"
      >
        <IconButton
          icon={<MdArrowBack />}
          onClick={prevAnnouncement}
          aria-label="Previous Announcement"
          variant="ghost"
          color={useColorModeValue("gray.500", "gray.400")}
          size="md"
          borderRadius="full"
          _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
          _active={{ bg: useColorModeValue("gray.300", "gray.700") }}
          transition="background-color 0.3s ease"
        />
        <Text
          flex="1"
          textAlign="center"
          fontSize={{ base: 'md', md: 'lg' }} // Tamanho da fonte mais ajustado
          fontWeight="semibold"
          color={useColorModeValue("gray.700", "gray.200")}
          px="4"
          whiteSpace="normal" // Permite quebra de linha se necessário
          overflow="visible"
          wordBreak="break-word" // Garante que palavras longas sejam quebradas
          animation={`${fadeIn} 0.5s ease`}
        >
          {announcements[currentIndex]}
        </Text>
        <IconButton
          icon={<MdArrowForward />}
          onClick={nextAnnouncement}
          aria-label="Next Announcement"
          variant="ghost"
          color={useColorModeValue("gray.500", "gray.400")}
          size="md"
          borderRadius="full"
          _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
          _active={{ bg: useColorModeValue("gray.300", "gray.700") }}
          transition="background-color 0.3s ease"
        />
      </Flex>

      <Button
        as="a"
        href="https://wa.me/5527988417973"
        target="_blank"
        rel="noopener noreferrer"
        mt="6"
        width="full"
        bg={buttonBg}
        color={buttonTextColor}
        size="lg"
        borderRadius="12px"
        shadow="md"
        _hover={{ bg: buttonHoverBg, shadow: 'lg' }}
        transition="box-shadow 0.2s ease"
        fontSize={{ base: 'sm', md: 'md' }}
        px={{ base: '4', md: '6' }}
        position="relative"
        sx={{
          animation: `${pulseEffect} 3s infinite ease-in-out`,
        }}
      >
        Investigação particular - Fale Conosco
      </Button>
    </Box>
  );
};

export default AnnouncementCarousel;
