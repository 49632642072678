// src/routes.js
import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome, MdLock } from 'react-icons/md';

// Admin Imports
import MainDashboard from './views/admin/default';

// Auth Imports
import Login from './views/auth/login/page';
import Signup from './views/auth/signup/page';
import ForgotPassword from './views/auth/forgot-password/page';
import ResetPassword from './views/auth/reset-password/page';

const routes = [
  {
    name: 'Página Inicial',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
    sidebar: true, // Aparece na sidebar
  },
  {
    name: 'Login',
    layout: '/auth',
    path: '/login',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <Login />,
    sidebar: false, // Não aparece na sidebar
  },
  {
    name: 'Criar Conta',
    layout: '/auth',
    path: '/signup',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <Signup />,
    sidebar: false, // Não aparece na sidebar
  },
  {
    name: 'Esqueci a Senha',
    layout: '/auth',
    path: '/forgot-password',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <ForgotPassword />,
    sidebar: false, // Não aparece na sidebar
  },
  {
    name: 'Redefinir Senha',
    layout: '/auth',
    path: '/reset-password',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <ResetPassword />,
    sidebar: false, // Não aparece na sidebar
  },
  // ... outras rotas que devem aparecer na sidebar
];

export default routes;
