import React from 'react';
import { motion } from 'framer-motion';
import { Box, Button, Text, VStack, Divider, useColorModeValue, useBreakpointValue, IconButton, Flex, SimpleGrid, GridItem } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

// Definição de animações com Framer Motion
const popupVariants = {
  hidden: { opacity: 0, y: "-50%", x: "-50%", scale: 0.9 },
  visible: { opacity: 1, scale: 1, y: "-50%", x: "-50%", transition: { duration: 0.4, type: "spring" } },
  exit: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } },
};

const Popup = ({ isOpen, onClose, data, consultaTipo }) => {
  // Definindo as cores e outros valores condicionalmente
  const backdropColor = useColorModeValue('rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.6)');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.100');
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');

  if (!isOpen || !data || Object.keys(data).length === 0) return null;

  const titulo = consultaTipo ? `Dados da Consulta ${consultaTipo}` : 'Dados da Consulta';

  // Função para melhorar os rótulos dos parâmetros retornados do backend
  const formatLabel = (label) => {
    const labelMapping = {
      'nome_completo': 'Nome Completo',
      'data_nascimento': 'Data de Nascimento',
      'cpf': 'CPF',
      'endereco': 'Endereço',
      'cep': 'CEP',
      'telefone': 'Telefone',
      'estado_civil': 'Estado Civil',
      'renda_anual': 'Renda Anual',
      // Adicione outros mapeamentos conforme necessário
    };

    // Substitui o rótulo se ele estiver no mapeamento, senão apenas formata o texto
    return labelMapping[label] || label
      .replace(/_/g, ' ')        // Substitui underscores por espaços
      .toLowerCase()             // Coloca tudo em minúsculas
      .replace(/\b\w/g, char => char.toUpperCase());  // Capitaliza a primeira letra de cada palavra
  };

  // Renderiza os dados formatados em duas colunas, omitindo os campos sem valor
  const renderFormattedData = (data) => {
    return Object.entries(data)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '') // Filtra apenas valores válidos
      .map(([label, value], index) => (
        <GridItem
          key={index}
          p="10px"
          borderBottom={`1px solid ${borderColor}`}
          pb="15px"
        >
          <Text fontWeight="medium" color={labelColor} fontSize="sm" textTransform="uppercase">
            {formatLabel(label)}
          </Text>
          <Text mt="5px" color={textColor} fontSize="md" fontWeight="bold">
            {renderValue(value)}
          </Text>
        </GridItem>
      ));
  };

  // Renderiza os valores, lidando com arrays, objetos, ou valores simples
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      // Se for um array, renderiza cada item do array que não seja vazio
      return value
        .filter(item => item !== null && item !== undefined && item !== '')
        .map((item, idx) => (
          <Box key={idx} mb="5px">
            {typeof item === 'object' ? renderFormattedData(item) : (
              <Text>{item}</Text>
            )}
          </Box>
        ));
    } else if (typeof value === 'object' && value !== null) {
      // Se for um objeto, renderiza suas chaves e valores, omitindo os campos vazios
      return (
        <Box mt="5px">
          {Object.entries(value)
            .filter(([_, subValue]) => subValue !== null && subValue !== undefined && subValue !== '')
            .map(([subKey, subValue]) => (
              <Box key={subKey} mb="5px">
                <Text color={textColor} fontSize="md">
                  <strong>{formatLabel(subKey)}:</strong> {renderValue(subValue)}
                </Text>
              </Box>
            ))}
        </Box>
      );
    } else {
      return value; // Retorna o valor diretamente se for uma string simples e não vazia
    }
  };

  return (
    <>
      {/* Background com animação */}
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg={backdropColor}
        zIndex="overlay"
        onClick={onClose}
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />

      {/* Popup aprimorado com animações suaves */}
      <Box
        as={motion.div}
        variants={popupVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        p={isMobile ? "20px" : "40px"}
        bg={bgColor}
        borderRadius="lg"
        boxShadow="2xl"
        zIndex="overlay"
        maxW="90vw"
        maxH="80vh"
        minW={isMobile ? "85%" : "800px"}
        overflowY="auto"
        border="1px solid"
        borderColor={borderColor}
      >
        <IconButton
          icon={<CloseIcon />}
          position="absolute"
          top="10px"
          right="10px"
          onClick={onClose}
          aria-label="Fechar popup"
          variant="ghost"
          _hover={{ bg: hoverBg }}
        />

        <Text fontSize={isMobile ? "2xl" : "3xl"} fontWeight="bold" mb="20px" textAlign="center" color={textColor}>
          {titulo}
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px">
          {renderFormattedData(data)}
        </SimpleGrid>

        <Flex justifyContent="center" mt="30px">
          <Button onClick={onClose} colorScheme="blue" boxShadow="xl" px="8" py="4" fontSize="lg" _hover={{ boxShadow: '2xl' }}>
            Fechar
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default Popup;
