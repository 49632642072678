import React, { useState } from 'react'; 
import {
  Button,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  Heading,
} from '@chakra-ui/react';
import PlansPopup from './PlansPopup'; // Certifique-se de ajustar o caminho da importação para onde o componente estiver salvo
import PixPayment from './PixPayment'; // Componente PixPayment para exibir QR Code

const PaymentPopup = ({ isOpen, onClose }) => {
  const [isPlansOpen, setPlansOpen] = useState(false); // Estado para controlar o modal dos planos
  const [isPixOpen, setPixOpen] = useState(false); // Estado para controlar o modal do Pix

  const handlePlanClick = () => {
    setPlansOpen(true); // Abre o modal dos planos
  };

  const handlePixClick = () => {
    setPixOpen(true); // Abre o modal do Pix
  };

  const handlePlansClose = () => {
    setPlansOpen(false); // Fecha o modal dos planos
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          bg="white"
          borderRadius="lg"
          boxShadow="2xl"
          p={6}
          maxW={{ base: '90%', md: '500px' }} // Reduzi o tamanho no mobile
          mx="auto" // Adiciona margens laterais automáticas
        >
          <ModalHeader textAlign="center">
            <Heading size={{ base: 'md', md: 'lg' }} color="gray.800">
              Acesso Premium
            </Heading>
          </ModalHeader>

          <ModalBody>
            <VStack spacing="20px" textAlign="center">
              <Text fontSize={{ base: 'sm', md: 'lg' }} color="gray.600">
                Aproveite todos os benefícios de nossos planos e faça consultas ilimitadas. Escolha uma das opções abaixo para continuar:
              </Text>

              <Button
                colorScheme="orange"
                onClick={handlePlanClick} // Abre o modal dos planos
                w="full"
                maxW={{ base: '100%', md: '100%' }} // Garante que o botão ocupe toda a largura no mobile
                boxShadow="lg"
                py={{ base: '3', md: '6' }} // Ajuste o padding no mobile
                fontSize={{ base: 'sm', md: 'lg' }} // Ajuste o tamanho da fonte no mobile
                fontWeight="bold"
                borderRadius="full"
                _hover={{ bg: 'orange.500' }}
                wordBreak="break-word" // Garante que o texto seja quebrado corretamente
              >
                👑 Assinar Plano Premium
              </Button>

              <Button
                colorScheme="green"
                onClick={handlePixClick} // Abre o modal do Pix
                w="full"
                maxW={{ base: '100%', md: '100%' }} // Garante que o botão ocupe toda a largura no mobile
                boxShadow="lg"
                py={{ base: '3', md: '6' }} // Ajuste o padding no mobile
                fontSize={{ base: 'sm', md: 'lg' }} // Ajuste o tamanho da fonte no mobile
                fontWeight="bold"
                borderRadius="full"
                _hover={{ bg: 'green.500' }}
                wordBreak="break-word" // Garante que o texto seja quebrado corretamente
              >
                💸 Pagar Consulta Avulsa via Pix
              </Button>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="center" w="full">
              <Button
                onClick={onClose}
                colorScheme="blue"
                px="8"
                py="4"
                fontSize={{ base: 'sm', md: 'lg' }} // Ajuste no tamanho da fonte no mobile
                _hover={{ bg: 'blue.600' }}
                borderRadius="md"
              >
                Fechar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal dos Planos */}
      <PlansPopup isOpen={isPlansOpen} onClose={handlePlansClose} />

      {/* Modal do Pagamento via Pix */}
      <PixPayment isOpen={isPixOpen} onClose={() => setPixOpen(false)} />
    </>
  );
};

export default PaymentPopup;
