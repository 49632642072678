import React from 'react';
import {
  Button,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Heading,
  Box,
  Stack,
  IconButton,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';

const PlansPopup = ({ isOpen, onClose, onBackClick }) => {

  const createCustomerAndCheckout = async (vipPlan) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('Token de autenticação não encontrado');

      const customerResponse = await fetch('https://app.unlockedpotent.com/stripe/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!customerResponse.ok) throw new Error('Erro ao criar o cliente');

      const sessionResponse = await fetch('https://querubimcompany.com/stripe/checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          vipPlan,
        }),
      });

      const sessionData = await sessionResponse.json();
      if (!sessionResponse.ok) throw new Error('Erro ao iniciar a sessão de checkout');

      window.location.href = sessionData.data.url;
    } catch (error) {
      console.error('Erro no processo de assinatura:', error);
      alert('Houve um erro ao processar sua assinatura. Tente novamente.');
    }
  };

  // Renderiza o componente somente se o modal estiver aberto
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="white"
        borderRadius="lg"
        boxShadow="2xl"
        p={4}
        maxW={{ base: '90%', md: '400px', lg: '600px' }}
        mx="auto"
        my={{ base: "1vh", md: "auto" }}
      >
        <ModalHeader textAlign="center" p={2}>
          <Flex justify="space-between" align="center">
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={onBackClick || onClose}
              variant="ghost"
              fontSize="20px"
              _hover={{ bg: 'gray.100' }}
              aria-label="Voltar"
            />
            <Heading size="md" color="gray.800" textAlign="center" w="full">
              Escolha seu Plano VIP
            </Heading>
          </Flex>
        </ModalHeader>

        <ModalBody px={4}>
          <VStack spacing={4} textAlign="center">

            {/* Plano VIP 1 */}
            <Box
              w="full"
              p={4}
              borderRadius="lg"
              bg="gray.50"
              boxShadow="sm"
              border="1px solid"
              borderColor="gray.200"
            >
              <Stack direction="column" spacing={2} textAlign="left">
                <Heading size="sm" color="blue.700">Plano VIP 1</Heading>
                <Text fontSize="sm" color="gray.600">R$ 150,00/mês</Text>
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="blue.500" />
                    <Text fontSize="sm" color="gray.500">Até 20 consultas diárias</Text>
                  </HStack>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="blue.500" />
                    <Text fontSize="sm" color="gray.500">Ideal para consultas frequentes</Text>
                  </HStack>
                </VStack>
                <Button
                  colorScheme="blue"
                  onClick={() => createCustomerAndCheckout('VIP1')}
                  w="full"
                  boxShadow="lg"
                  py={3}
                  fontSize="sm"
                  fontWeight="bold"
                  borderRadius="full"
                  _hover={{ bg: 'blue.600' }}
                >
                  Assinar
                </Button>
              </Stack>
            </Box>

            {/* Plano VIP 2 */}
            <Box
              w="full"
              p={4}
              borderRadius="lg"
              bg="gray.50"
              boxShadow="sm"
              border="1px solid"
              borderColor="gray.200"
            >
              <Stack direction="column" spacing={2} textAlign="left">
                <Heading size="sm" color="green.700">Plano VIP 2</Heading>
                <Text fontSize="sm" color="gray.600">R$ 250,00/mês</Text>
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="green.500" />
                    <Text fontSize="sm" color="gray.500">Até 40 consultas diárias</Text>
                  </HStack>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="green.500" />
                    <Text fontSize="sm" color="gray.500">Suporte liberado</Text>
                  </HStack>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="green.500" />
                    <Text fontSize="sm" color="gray.500">1 consulta investigativa completa</Text>
                  </HStack>
                </VStack>
                <Button
                  colorScheme="green"
                  onClick={() => createCustomerAndCheckout('VIP2')}
                  w="full"
                  boxShadow="lg"
                  py={3}
                  fontSize="sm"
                  fontWeight="bold"
                  borderRadius="full"
                  _hover={{ bg: 'green.600' }}
                >
                  Assinar
                </Button>
              </Stack>
            </Box>

            {/* Plano VIP 3 */}
            <Box
              w="full"
              p={4}
              borderRadius="lg"
              bg="gray.50"
              boxShadow="sm"
              border="1px solid"
              borderColor="gray.200"
            >
              <Stack direction="column" spacing={2} textAlign="left">
                <Heading size="sm" color="orange.700">Plano VIP 3</Heading>
                <Text fontSize="sm" color="gray.600">R$ 400,00/mês</Text>
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="orange.500" />
                    <Text fontSize="sm" color="gray.500">Até 100 consultas diárias</Text>
                  </HStack>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="orange.500" />
                    <Text fontSize="sm" color="gray.500">Suporte especializado</Text>
                  </HStack>
                  <HStack>
                    <Icon as={CheckCircleIcon} color="orange.500" />
                    <Text fontSize="sm" color="gray.500">3 consultas investigativas completas</Text>
                  </HStack>
                </VStack>
                <Button
                  colorScheme="orange"
                  onClick={() => createCustomerAndCheckout('VIP3')}
                  w="full"
                  boxShadow="lg"
                  py={3}
                  fontSize="sm"
                  fontWeight="bold"
                  borderRadius="full"
                  _hover={{ bg: 'orange.600' }}
                >
                  Assinar
                </Button>
              </Stack>
            </Box>

          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlansPopup;
