import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  Icon,
  Image,
  Link as ChakraLink, // Chakra UI Link
} from "@chakra-ui/react";
import DefaultAuth from "../../../layouts/auth/Default";
import illustration from "../../../assets/img/auth/auth.png";
import buscaAiLogo from "../../../assets/images/buscaailogo.png"; // Importando a nova logo
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function Login() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => setShow(!show);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    if (!email || !password) {
      setError("Por favor, preencha todos os campos.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch("https://app.unlockedpotent.com/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("authToken", data.data.access_token); // Salva o token no localStorage
        navigate("/admin/default"); // Redireciona para a página protegida
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Erro ao tentar fazer login.");
      }
    } catch (err) {
      setError("Erro de conexão com o servidor.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h={{ base: "100vh", md: "100%" }}
        alignItems={{ base: "center", md: "start" }}
        justifyContent="center"
        mb={{ base: "0", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "-15vh", md: "10vh" }}
        flexDirection="column"
      >
        {/* Logo no topo somente para o mobile */}
        <Box display={{ base: "block", md: "none" }} mb="20px" textAlign="center">
          <Image src={buscaAiLogo} alt="Busca Aí Logo" maxW="150px" mx="auto" />
        </Box>

        <Box w="100%" maxW="400px" mx="auto">
          <Heading color={textColor} fontSize={{ base: "20px", md: "36px" }} mb="10px">
            Entrar
          </Heading>
          <Text color={textColorDetails} fontSize="md" ms="4px" mb="36px">
            Insira suas credenciais para acessar o painel.
          </Text>
          {error && (
            <Alert status="error" mb="20px">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="500">
              Email
            </FormLabel>
            <Input
              variant="outline"
              fontSize="16px" // Tamanho mínimo para evitar o zoom no mobile
              ms="4px"
              placeholder="Seu email"
              mb="24px"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="500">
              Senha
            </FormLabel>
            <InputGroup size="lg">
              <Input
                variant="outline"
                fontSize="16px" // Tamanho mínimo para evitar o zoom no mobile
                ms="4px"
                placeholder="Sua senha"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement>
                <Icon
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  color="secondaryGray.600"
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Link Esqueci minha senha */}
          <Flex justifyContent="flex-end" mt="10px">
            <ChakraLink
              as={Link}
              to="/auth/forgot-password"
              color="blue.500"
              fontSize="sm"
              _hover={{ textDecoration: "underline" }}
            >
              Esqueci minha senha
            </ChakraLink>
          </Flex>

          <Button
            variant="brand"
            fontSize="sm"
            w="100%"
            h="50"
            mt="30px"
            isLoading={loading}
            onClick={handleSubmit}
          >
            Entrar
          </Button>

          {/* Link Criar conta */}
          <Flex justifyContent="center" mt="20px">
            <ChakraLink
              as={Link}
              to="/auth/signup"
              color="blue.500"
              fontSize="sm"
              _hover={{ textDecoration: "underline" }}
            >
              Não tem uma conta? Criar agora
            </ChakraLink>
          </Flex>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default Login;